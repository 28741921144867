import { Box } from "@mui/material";
import React, { useEffect } from "react";

const AzanCalculation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h2 className="privacy_policy_heading">Prayer Calculation Methods</h2>
      <Box sx={{ width: { xs: "90%", md: "80%", lg: "70%" }, margin: "auto" }}>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Prayer Time Calculation
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          Muslims are supposed to perform five prayers a day. Each prayer is
          given a certain prescribed time during which it must be performed.
          This document briefly describes these times and explains how they can
          be calculated mathematically.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          To project the exact time period for each prayer (and also for
          fasting), we need to determine nine points of time per a day.
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Calculation Methods
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          There are different conventions for calculating prayer times. The
          following table lists several well-known conventions currently in use
          in various regions:
        </p>

        <Box className="table" sx={{ width: { xs: "98%", md: "90%" } }}>
          <table>
            <th>Method</th>
            <th>Abrr.</th>
            <th>Region Used</th>
            <tr>
              <td>Muslim World League</td>
              <td>MWL</td>
              <td>Europe, Far East, parts of US</td>
            </tr>
            <tr>
              <td>Islamic Society of North America</td>
              <td>ISNA</td>
              <td>North America (US and Canada)</td>
            </tr>
            <tr>
              <td>Egyptian General Authority of Survey</td>
              <td>Egypt</td>
              <td>Africa, Syria, Lebanon, Malaysia</td>
            </tr>
            <tr>
              <td>Umm al-Qura University, Makkah</td>
              <td>Makkah</td>
              <td>Arabian Peninsula</td>
            </tr>
            <tr>
              <td>University of Islamic Sciences, Karachi</td>
              <td>Karachi </td>
              <td>Pakistan, Afganistan, Bangladesh, India</td>
            </tr>
            <tr>
              <td>Institute of Geophysics, University of Tehran</td>
              <td>Tehran </td>
              <td>Iran, Some Shia communities</td>
            </tr>
            <tr>
              <td>Shia Ithna Asheri, Leva Research Institute, Qum </td>
              <td>Jafari </td>
              <td>Some Shia communities worldwide</td>
            </tr>
          </table>
        </Box>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Calculating Parameters
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          The table below shows the default calculating parameters for each
          calculation method:
        </p>

        <Box className="table" sx={{ width: { xs: "98%", md: "90%" } }}>
          <table>
            <th>Method</th>
            <th>Fajr Angle</th>
            <th>Isha</th>
            <th>Maghrib</th>
            <th>Midnight</th>
            <tr>
              <td>MWL</td>
              <td>18°</td>
              <td>17°</td>
              <td>= Senset</td>
              <td>mid Sunset to Sunrise</td>
            </tr>
            <tr>
              <td>ISNA</td>
              <td>15°</td>
              <td>15°</td>
              <td>= Senset</td>
              <td>mid Sunset to Sunrise</td>
            </tr>
            <tr>
              <td>Egypt</td>
              <td>19.5°</td>
              <td>17.5°</td>
              <td>= Senset</td>
              <td>mid Sunset to Sunrise</td>
            </tr>
            <tr>
              <td>Makkah</td>
              <td>18.5°</td>
              <td>
                90 min after Maghrib
                <br /> 120 min during Ramadan
              </td>
              <td>= Senset</td>
              <td>mid Sunset to Sunrise</td>
            </tr>
            <tr>
              <td>Karachi</td>
              <td>18°</td>
              <td>18°</td>
              <td>= Senset</td>
              <td>mid Sunset to Sunrise</td>
            </tr>
            <tr>
              <td>Tehran</td>
              <td>17.7°</td>
              <td>14°</td>
              <td>4.5°</td>
              <td>mid Sunset to Fajr</td>
            </tr>
            <tr>
              <td>Jafari</td>
              <td>16°</td>
              <td>14°</td>
              <td>4°</td>
              <td>mid Sunset to Fajr</td>
            </tr>
          </table>
        </Box>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Notes:</h2>
        <p className="privacy_policy_content privacy_policy_content2">
          * Isha angle is not explicitly defined in Tehran method.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Asr</h2>
        <p className="privacy_policy_content privacy_policy_content2">
          There are two main opinions on how to calculate Asr time. Most schools
          (including Shafi'i, Maliki, Ja'fari, and Hanbali) say it is at the
          time when the length of any object's shadow equals the length of the
          object itself plus the length of that object's shadow at noon. The
          dominant opinion in the Hanafi school says that Asr begins when the
          length of any object's shadow is twice the length of the object plus
          the length of that object's shadow at noon.
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Maghrib</h2>
        <p className="privacy_policy_content privacy_policy_content2">
          In the Sunni's point of view, the time for Maghrib prayer begins once
          the Sun has completely set beneath the horizon, that is, Maghrib =
          Sunset (some calculators suggest 1 to 3 minutes after Sunset for
          precaution). In the Shia's view, however, the dominant opinion is that
          as long as the redness in the eastern sky appearing after sunset has
          not passed overhead, Maghrib prayer should not be performed. It is
          usually taken into consideration by assuming a twilight angle like
          Maghrib = Dhuhr + T(4).
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Midnight</h2>
        <p className="privacy_policy_content privacy_policy_content2">
          Midnight is generally calculated as the mean time from Sunset to
          Sunrise, i.e., Midnight = 1/2(Sunrise - Sunset). In Shia point of
          view, the juridical midnight (the ending time for performing Isha
          prayer) is the mean time from Sunset to Fajr, i.e., Midnight =
          1/2(Fajr - Sunset).
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Higher Latitudes
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          In locations at higher latitude, twilight may persist throughout the
          night during some months of the year. In these abnormal periods, the
          determination of Fajr and Isha is not possible using the usual
          formulas mentioned in the previous section. To overcome this problem,
          several solutions have been proposed, three of which are described
          below.
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Middle of the Night
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          In this method, the period from sunset to sunrise is divided into two
          halves. The first half is considered to be the "night" and the other
          half as "day break". Fajr and Isha in this method are assumed to be at
          mid-night during the abnormal periods.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          One-Seventh of the Night
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          In this method, the period between sunset and sunrise is divided into
          seven parts. Isha begins after the first one-seventh part, and Fajr is
          at the beginning of the seventh part.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Angle-Based Method
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          This is an intermediate solution, used by some recent prayer time
          calculators. Let α be the twilight angle for Isha, and let t = α/60.
          The period between sunset and sunrise is divided into t parts. Isha
          begins after the first part. For example, if the twilight angle for
          Isha is 15, then Isha begins at the end of the first quarter (15/60)
          of the night. Time for Fajr is calculated similarly.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          In case Maghrib is not equal to Sunset, we can apply the above rules
          to Maghrib as well to make sure that Maghrib always falls between
          Sunset and Isha during the abnormal periods.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Refrences</h2>
        <Box sx={{ ml: 2, mt: 2 }}>
          <p className="privacy_policy_content privacy_policy_content2">
            • The Determination of Salat Times, by Dr. Monzur Ahmed.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            • Approximate Solar Coordinates, by U.S. Naval Observatory.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            • The Islamic Prayer Times, by Professor Tariq Muneer.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            • Wikipedia, the free encyclopedia.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            • All information provided to help Muslims understand prayer time
            calculation.
          </p>
        </Box>
      </Box>
    </div>
  );
};

export default AzanCalculation;
