import { Box } from "@mui/material";
import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ minHeight: "90vh" }}>
      <h2 className="about_heading">About Us</h2>
      <Box sx={{ width: { xs: "90%", md: "80%", lg: "70%" }, margin: "auto" }}>
        <p className="privacy_policy_content privacy_policy_content2">
          ALLHUMDULLIAH, I have created the World Azan Time website and App
          available on iOS and Android.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          The aim is to provide the most accurate prayer time for Muslim all
          over the word as SADQAH JARIYA for our parents and loved ones.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          InShaAllah, this will continue source of Sadaqah Jarriya for everyone
          who share and use this App and website to observe prayers.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          This App and website have options to adjust prayer time as per Hanafi
          or Shafi Fiqh, location and use angle based or 1/7 rule calculation to
          find the most accurate time of the prayers. In addition, it provide
          Qibla direction, prayer notification and Quran recitation with
          translation.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          There is a slight chance of some time difference between your local
          mosque and this website and the App, depending on the calculation
          method adopted by your local mosque.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          We reassure you, that we don’t intend to store and share your personal
          information, except third parties who may use some information to
          maintain the functionality of this service.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          Insha’Allah, this will continue source of Sadaqah Jarriya for everyone
          who share and use this App and website to observe prayers.
        </p>
        <div style={{ marginTop: "40px" }}>
          <p className="privacy_policy_content privacy_policy_content2">
            Walakum Asalam
          </p>
          <p
            className="privacy_policy_content privacy_policy_content2"
            style={{ marginTop: "5px" }}
          >
            Jazak Allah Khair
          </p>
        </div>
      </Box>
    </div>
  );
};

export default AboutUs;
