export const data = [
  [
    {
      number: 1,
      juz: 1,
      from: 0,
      to: 7,
      filter: false,
      name: "سُورَةُ ٱلْفَاتِحَةِ",
      englishName: "Al-Faatiha",
      englishNameTranslation: "The Opening",
      ayahas: "7",
    },
    {
      number: 2,
      juz: 1,
      from: 0,
      filter: true,
      to: 142,
      name: "سُورَةُ البَقَرَةِ",
      englishName: "Al-Baqara",
      englishNameTranslation: "The Cow",
      ayahas: "286",
    },
  ],

  [
    {
      juz: 2,
      from: 142,
      filter: true,
      to: 259,
      number: 2,
      name: "سُورَةُ البَقَرَةِ",
      englishName: "Al-Baqara",
      englishNameTranslation: "The Cow",
      ayahas: "286",
    },
  ],

  [
    {
      number: 2,
      juz: 3,
      from: 259,
      to: 286,
      filter: true,
      name: "سُورَةُ البَقَرَةِ",
      englishName: "Al-Baqara",
      englishNameTranslation: "The Cow",
      ayahas: "286",
    },
    {
      number: 3,
      juz: 3,
      from: 0,
      filter: true,
      to: 92,
      name: "سُورَةُ آلِ عِمۡرَانَ",
      englishName: "Aal-i-Imraan",
      englishNameTranslation: "The Family of Imraan",
      ayahas: "200",
    },
  ],

  [
    {
      number: 3,
      juz: 4,
      from: 92,
      filter: true,
      to: 108,
      name: "سُورَةُ آلِ عِمۡرَانَ",
      englishName: "Aal-i-Imraan",
      englishNameTranslation: "The Family of Imraan",
      ayahas: "200",
    },
    {
      number: 4,
      juz: 4,
      from: 0,
      filter: true,
      to: 23,
      name: "سُورَةُ النِّسَاءِ",
      englishName: "An-Nisaa",
      englishNameTranslation: "The Women",
      ayahas: "176",
    },
  ],

  [
    {
      number: 4,
      juz: 5,
      from: 23,
      filter: true,
      to: 148,
      name: "سُورَةُ النِّسَاءِ",
      englishName: "An-Nisaa",
      englishNameTranslation: "The Women",
      ayahas: "176",
    },
  ],

  [
    {
      number: 4,
      juz: 6,
      from: 148,
      filter: true,
      to: 176,
      name: "سُورَةُ النِّسَاءِ",
      englishName: "An-Nisaa",
      englishNameTranslation: "The Women",
      ayahas: "176",
    },
    {
      number: 5,
      juz: 6,
      from: 0,
      filter: true,
      to: 81,
      name: "سُورَةُ المَائـِدَةِ",
      englishName: "Al-Maaida",
      englishNameTranslation: "The Table",
      ayahas: "120",
    },
  ],

  [
    {
      number: 5,
      juz: 7,
      from: 81,
      filter: true,
      to: 140,
      name: "سُورَةُ المَائـِدَةِ",
      englishName: "Al-Maaida",
      englishNameTranslation: "The Table",
      ayahas: "120",
    },
    {
      number: 6,
      juz: 7,
      from: 0,
      filter: true,
      to: 110,
      name: "سُورَةُ الأَنۡعَامِ",
      englishName: "Al-An'aam",
      englishNameTranslation: "The Cattle",
      ayahas: "165",
    },
  ],

  [
    {
      number: 6,
      juz: 8,
      from: 110,
      filter: true,
      to: 165,
      name: "سُورَةُ الأَنۡعَامِ",
      englishName: "Al-An'aam",
      englishNameTranslation: "The Cattle",
      ayahas: "165",
    },
    {
      number: 7,
      juz: 8,
      from: 0,
      filter: true,
      to: 87,
      name: "سُورَةُ الأَعۡرَافِ",
      englishName: "Al-A'raaf",
      englishNameTranslation: "The Heights",
      ayahas: "206",
    },
  ],

  [
    {
      number: 7,
      juz: 9,
      from: 87,
      filter: true,
      to: 206,
      name: "سُورَةُ الأَعۡرَافِ",
      englishName: "Al-A'raaf",
      englishNameTranslation: "The Heights",
      ayahas: "206",
    },
    {
      number: 8,
      juz: 9,
      from: 0,
      filter: true,
      to: 40,
      name: "سُورَةُ الأَنفَالِ",
      englishName: "Al-Anfaal",
      englishNameTranslation: "The Spoils of War",
      ayahas: "75",
    },
  ],

  [
    {
      number: 8,
      juz: 10,
      from: 40,
      filter: true,
      to: 75,
      name: "سُورَةُ الأَنفَالِ",
      englishName: "Al-Anfaal",
      englishNameTranslation: "The Spoils of War",
      ayahas: "75",
    },
    {
      number: 9,
      juz: 10,
      from: 0,
      filter: true,
      to: 92,
      name: "سُورَةُ التَّوۡبَةِ",
      englishName: "At-Tawba",
      englishNameTranslation: "The Repentance",
      ayahas: "129",
    },
  ],

  [
    {
      number: 9,
      juz: 11,
      from: 92,
      filter: true,
      to: 129,
      name: "سُورَةُ التَّوۡبَةِ",
      englishName: "At-Tawba",
      englishNameTranslation: "The Repentance",
      ayahas: "129",
    },
    {
      number: 10,
      juz: 11,
      from: 0,
      filter: false,
      to: 109,
      name: "سُورَةُ يُونُسَ",
      englishName: "Yunus",
      englishNameTranslation: "Jonas",
      ayahas: "109",
    },
    {
      number: 11,
      juz: 11,
      from: 0,
      filter: true,
      to: 6,
      name: "سُورَةُ هُودٍ",
      englishName: "Hud",
      englishNameTranslation: "Hud",
      ayahas: "123",
    },
  ],

  [
    {
      number: 11,
      juz: 12,
      from: 6,
      filter: true,
      to: 123,
      name: "سُورَةُ هُودٍ",
      englishName: "Hud",
      englishNameTranslation: "Hud",
      ayahas: "123",
    },
    {
      number: 12,
      juz: 12,
      from: 0,
      filter: true,
      to: 50,
      name: "سُورَةُ يُوسُفَ",
      englishName: "Yusuf",
      englishNameTranslation: "Joseph",
      ayahas: "111",
    },
  ],

  [
    {
      number: 12,
      juz: 13,
      from: 50,
      filter: true,
      to: 111,
      name: "سُورَةُ يُوسُفَ",
      englishName: "Yusuf",
      englishNameTranslation: "Joseph",
      ayahas: "111",
    },
    {
      number: 13,
      juz: 13,
      from: 0,
      filter: false,
      to: 43,
      name: "سُورَةُ الرَّعۡدِ",
      englishName: "Ar-Ra'd",
      englishNameTranslation: "The Thunder",
      ayahas: "43",
    },
    {
      number: 14,
      juz: 13,
      from: 0,
      filter: false,
      to: 53,
      name: "سُورَةُ إِبۡرَاهِيمَ",
      englishName: "Ibrahim",
      englishNameTranslation: "Abraham",
      ayahas: "52",
    },
  ],
  [
    {
      number: 15,
      juz: 14,
      from: 0,
      filter: false,
      to: 99,
      name: "سُورَةُ الحِجۡرِ",
      englishName: "Al-Hijr",
      englishNameTranslation: "The Rock",
      ayahas: "99",
    },
    {
      number: 16,
      juz: 14,
      from: 99,
      filter: false,
      to: 28,
      name: "سُورَةُ النَّحۡلِ",
      englishName: "An-Nahl",
      englishNameTranslation: "The Bee",
      ayahas: "128",
    },
  ],
  [
    {
      number: 17,
      juz: 15,
      from: 0,
      filter: false,
      to: 111,
      name: "سُورَةُ الإِسۡرَاءِ",
      englishName: "Al-Israa",
      englishNameTranslation: "The Night Journey",
      ayahas: "111",
    },
    {
      number: 18,
      juz: 15,
      from: 0,
      filter: true,
      to: 74,
      name: "سُورَةُ الكَهۡفِ",
      englishName: "Al-Kahf",
      englishNameTranslation: "The Cave",
      ayahas: "110",
    },
  ],

  [
    {
      number: 18,
      juz: 16,
      from: 74,
      filter: true,
      to: 110,
      name: "سُورَةُ الكَهۡفِ",
      englishName: "Al-Kahf",
      englishNameTranslation: "The Cave",
      ayahas: "110",
    },
    {
      number: 19,
      juz: 16,
      from: 36,
      filter: false,
      to: 98,
      name: "سُورَةُ مَرۡيَمَ",
      englishName: "Maryam",
      englishNameTranslation: "Mary",
      ayahas: "98",
    },
    {
      number: 20,
      juz: 16,
      from: 134,
      filter: false,
      to: 135,
      name: "سُورَةُ طه",
      englishName: "Taa-Haa",
      englishNameTranslation: "Taa-Haa",
      ayahas: "135",
    },
  ],

  [
    {
      number: 21,
      juz: 17,
      from: 0,
      filter: false,
      to: 112,
      name: "سُورَةُ الأَنبِيَاءِ",
      englishName: "Al-Anbiyaa",
      englishNameTranslation: "The Prophets",
      ayahas: "112",
    },
    {
      number: 22,
      juz: 17,
      from: 112,
      filter: false,
      to: 78,
      name: "سُورَةُ الحَجِّ",
      englishName: "Al-Hajj",
      englishNameTranslation: "The Pilgrimage",
      ayahas: "78",
    },
  ],

  [
    {
      number: 23,
      juz: 18,
      from: 0,
      filter: false,
      to: 118,
      name: "سُورَةُ المُؤۡمِنُونَ",
      englishName: "Al-Muminoon",
      englishNameTranslation: "The Believers",
      ayahas: "118",
    },
    {
      number: 24,
      juz: 18,
      from: 118,
      filter: false,
      to: 64,
      name: "سُورَةُ النُّورِ",
      englishName: "An-Noor",
      englishNameTranslation: "The Light",
      ayahas: "64",
    },
    {
      number: 25,
      juz: 18,
      from: 0,
      filter: true,
      to: 20,
      name: "سُورَةُ الفُرۡقَانِ",
      englishName: "Al-Furqaan",
      englishNameTranslation: "The Criterion",
      ayahas: "77",
    },
  ],

  [
    {
      number: 25,
      juz: 19,
      from: 20,
      filter: true,
      to: 77,
      name: "سُورَةُ الفُرۡقَانِ",
      englishName: "Al-Furqaan",
      englishNameTranslation: "The Criterion",
      ayahas: "77",
    },
    {
      number: 26,
      juz: 19,
      from: 57,
      filter: false,
      to: 227,
      name: "سُورَةُ الشُّعَرَاءِ",
      englishName: "Ash-Shu'araa",
      englishNameTranslation: "The Poets",
      ayahas: "227",
    },
    {
      number: 27,
      juz: 19,
      from: 0,
      filter: true,
      to: 55,
      name: "سُورَةُ النَّمۡلِ",
      englishName: "An-Naml",
      englishNameTranslation: "The Ant",
      ayahas: "93",
    },
  ],

  [
    {
      number: 27,
      juz: 20,
      from: 55,
      filter: true,
      to: 93,
      name: "سُورَةُ النَّمۡلِ",
      englishName: "An-Naml",
      englishNameTranslation: "The Ant",
      ayahas: "93",
    },
    {
      number: 28,
      juz: 20,
      from: 38,
      filter: false,
      to: 88,
      name: "سُورَةُ القَصَصِ",
      englishName: "Al-Qasas",
      englishNameTranslation: "The Stories",
      ayahas: "88",
    },
    {
      number: 29,
      juz: 20,
      from: 0,
      filter: true,
      to: 45,
      name: "سُورَةُ العَنكَبُوتِ",
      englishName: "Al-Ankaboot",
      englishNameTranslation: "The Spider",
      ayahas: "69",
    },
  ],

  [
    {
      number: 29,
      juz: 21,
      from: 45,
      filter: true,
      to: 69,
      name: "سُورَةُ العَنكَبُوتِ",
      englishName: "Al-Ankaboot",
      englishNameTranslation: "The Spider",
      ayahas: "69",
    },
    {
      number: 30,
      juz: 21,
      from: 24,
      filter: false,
      to: 60,
      name: "سُورَةُ الرُّومِ",
      englishName: "Ar-Room",
      englishNameTranslation: "The Romans",
      ayahas: "60",
    },
    {
      number: 31,
      juz: 21,
      from: 84,
      filter: false,
      to: 34,
      name: "سُورَةُ لُقۡمَانَ",
      englishName: "Luqman",
      englishNameTranslation: "Luqman",
      ayahas: "34",
    },
    {
      number: 32,
      juz: 21,
      from: 118,
      filter: false,
      to: 30,
      name: "سُورَةُ السَّجۡدَةِ",
      englishName: "As-Sajda",
      englishNameTranslation: "The Prostration",
      ayahas: "30",
    },
    {
      number: 33,
      juz: 21,
      from: 0,
      filter: true,
      to: 30,
      name: "سُورَةُ الأَحۡزَابِ",
      englishName: "Al-Ahzaab",
      englishNameTranslation: "The Clans",
      ayahas: "73",
    },
  ],

  [
    {
      number: 33,
      juz: 22,
      from: 30,
      filter: true,
      to: 73,
      name: "سُورَةُ الأَحۡزَابِ",
      englishName: "Al-Ahzaab",
      englishNameTranslation: "The Clans",
      ayahas: "73",
    },
    {
      number: 34,
      juz: 22,
      from: 43,
      filter: false,
      to: 54,
      name: "سُورَةُ سَبَإٍ",
      englishName: "Saba",
      englishNameTranslation: "Sheba",
      ayahas: "54",
    },
    {
      number: 35,
      juz: 22,
      from: 97,
      filter: false,
      to: 45,
      name: "سُورَةُ فَاطِرٍ",
      englishName: "Faatir",
      englishNameTranslation: "The Originator",
      ayahas: "45",
    },
    {
      number: 36,
      juz: 22,
      from: 0,
      filter: true,
      to: 27,
      name: "سُورَةُ يسٓ",
      englishName: "Yaseen",
      englishNameTranslation: "Yaseen",
      ayahas: "83",
    },
  ],

  [
    {
      number: 36,
      juz: 23,
      from: 27,
      filter: true,
      to: 83,
      name: "سُورَةُ يسٓ",
      englishName: "Yaseen",
      englishNameTranslation: "Yaseen",
      ayahas: "83",
    },
    {
      number: 37,
      juz: 23,
      from: 56,
      filter: false,
      to: 182,
      name: "سُورَةُ الصَّافَّاتِ",
      englishName: "As-Saaffaat",
      englishNameTranslation: "Those drawn up in Ranks",
      ayahas: "182",
    },
    {
      number: 38,
      juz: 23,
      from: 238,
      filter: false,
      to: 88,
      name: "سُورَةُ صٓ",
      englishName: "Saad",
      englishNameTranslation: "The letter Saad",
      ayahas: "88",
    },
    {
      number: 39,
      juz: 23,
      from: 0,
      filter: true,
      to: 31,
      name: "سُورَةُ الزُّمَرِ",
      englishName: "Az-Zumar",
      englishNameTranslation: "The Groups",
      ayahas: "75",
    },
  ],

  [
    {
      number: 39,
      juz: 24,
      from: 31,
      filter: true,
      to: 75,
      name: "سُورَةُ الزُّمَرِ",
      englishName: "Az-Zumar",
      englishNameTranslation: "The Groups",
      ayahas: "75",
    },
    {
      number: 40,
      juz: 24,
      from: 44,
      filter: false,
      to: 85,
      name: "سُورَةُ غَافِرٍ",
      englishName: "Ghafir",
      englishNameTranslation: "The Forgiver",
      ayahas: "85",
    },
    {
      number: 41,
      juz: 24,
      from: 0,
      filter: true,
      to: 47,
      name: "سُورَةُ فُصِّلَتۡ",
      englishName: "Fussilat",
      englishNameTranslation: "Explained in detail",
      ayahas: "54",
    },
  ],

  [
    {
      number: 41,
      juz: 25,
      from: 47,
      filter: false,
      to: 54,
      name: "سُورَةُ فُصِّلَتۡ",
      englishName: "Fussilat",
      englishNameTranslation: "Explained in detail",
      ayahas: "54",
    },
    {
      number: 42,
      juz: 25,
      from: 8,
      filter: false,
      to: 53,
      name: "سُورَةُ الشُّورَىٰ",
      englishName: "Ash-Shura",
      englishNameTranslation: "Consultation",
      ayahas: "53",
    },
    {
      number: 43,
      juz: 25,
      from: 61,
      filter: false,
      to: 89,
      name: "سُورَةُ الزُّخۡرُفِ",
      englishName: "Az-Zukhruf",
      englishNameTranslation: "Ornaments of gold",
      ayahas: "89",
    },
    {
      number: 44,
      juz: 25,
      from: 150,
      filter: false,
      to: 59,
      name: "سُورَةُ الدُّخَانِ",
      englishName: "Ad-Dukhaan",
      englishNameTranslation: "The Smoke",
      ayahas: "59",
    },
    {
      number: 45,
      juz: 25,
      from: 209,
      filter: false,
      to: 39,
      name: "سُورَةُ الجَاثِيَةِ",
      englishName: "Al-Jaathiya",
      englishNameTranslation: "Crouching",
      ayahas: "37",
    },
  ],

  [
    {
      number: 46,
      juz: 26,
      from: 0,
      filter: false,
      to: 35,
      name: "سُورَةُ الأَحۡقَافِ",
      englishName: "Al-Ahqaf",
      englishNameTranslation: "The Dunes",
      ayahas: "35",
    },
    {
      number: 47,
      juz: 26,
      from: 35,
      filter: false,
      to: 38,
      name: "سُورَةُ مُحَمَّدٍ",
      englishName: "Muhammad",
      englishNameTranslation: "Muhammad",
      ayahas: "38",
    },
    {
      number: 48,
      juz: 26,
      from: 73,
      filter: false,
      to: 29,
      name: "سُورَةُ الفَتۡحِ",
      englishName: "Al-Fath",
      englishNameTranslation: "The Victory",
      ayahas: "29",
    },
    {
      number: 49,
      juz: 26,
      from: 102,
      filter: false,
      to: 18,
      name: "سُورَةُ الحُجُرَاتِ",
      englishName: "Al-Hujuraat",
      englishNameTranslation: "The Inner Apartments",
      ayahas: "18",
    },
    {
      number: 50,
      juz: 26,
      from: 120,
      filter: false,
      to: 45,
      name: "سُورَةُ قٓ",
      englishName: "Qaaf",
      englishNameTranslation: "The letter Qaaf",
      ayahas: "45",
    },
    {
      number: 51,
      juz: 26,
      from: 0,
      filter: true,
      to: 31,
      name: "سُورَةُ الذَّارِيَاتِ",
      englishName: "Adh-Dhaariyat",
      englishNameTranslation: "The Winnowing Winds",
      ayahas: "60",
    },
  ],

  [
    {
      number: 51,
      juz: 27,
      from: 31,
      filter: true,
      to: 60,
      name: "سُورَةُ الذَّارِيَاتِ",
      englishName: "Adh-Dhaariyat",
      englishNameTranslation: "The Winnowing Winds",
      ayahas: "60",
    },
    {
      number: 52,
      juz: 27,
      from: 30,
      filter: false,
      to: 49,
      name: "سُورَةُ الطُّورِ",
      englishName: "At-Tur",
      englishNameTranslation: "The Mount",
      ayahas: "49",
    },
    {
      number: 53,
      juz: 27,
      from: 79,
      filter: false,
      to: 62,
      name: "سُورَةُ النَّجۡمِ",
      englishName: "An-Najm",
      englishNameTranslation: "The Star",
      ayahas: "62",
    },
    {
      number: 54,
      juz: 27,
      from: 141,
      filter: false,
      to: 55,
      name: "سُورَةُ القَمَرِ",
      englishName: "Al-Qamar",
      englishNameTranslation: "The Moon",
      ayahas: "55",
    },
    {
      number: 55,
      juz: 27,
      from: 196,
      filter: false,
      to: 78,
      name: "سُورَةُ الرَّحۡمَٰن",
      englishName: "Ar-Rahmaan",
      englishNameTranslation: "The Beneficent",
      ayahas: "78",
    },
    {
      number: 56,
      juz: 27,
      from: 274,
      filter: false,
      to: 96,
      name: "سُورَةُ الوَاقِعَةِ",
      englishName: "Al-Waaqia",
      englishNameTranslation: "The Inevitable",
      ayahas: "96",
    },
    {
      number: 57,
      juz: 27,
      from: 370,
      filter: false,
      to: 30,
      name: "سُورَةُ الحَدِيدِ",
      englishName: "Al-Hadid",
      englishNameTranslation: "The Iron",
      ayahas: "29",
    },
  ],

  [
    {
      number: 58,
      juz: 28,
      from: 0,
      filter: false,
      to: 22,
      name: "سُورَةُ المُجَادلَةِ",
      englishName: "Al-Mujaadila",
      englishNameTranslation: "The Pleading Woman",
      ayahas: "22",
    },
    {
      number: 59,
      juz: 28,
      from: 22,
      filter: false,
      to: 24,
      name: "سُورَةُ الحَشۡرِ",
      englishName: "Al-Hashr",
      englishNameTranslation: "The Exile",
      ayahas: "24",
    },
    {
      number: 60,
      juz: 28,
      from: 46,
      filter: false,
      to: 13,
      name: "سُورَةُ المُمۡتَحنَةِ",
      englishName: "Al-Mumtahana",
      englishNameTranslation: "She that is to be examined",
      ayahas: "13",
    },
    {
      number: 61,
      juz: 28,
      from: 59,
      filter: false,
      to: 14,
      name: "سُورَةُ الصَّفِّ",
      englishName: "As-Saff",
      englishNameTranslation: "The Ranks",
      ayahas: "14",
    },
    {
      number: 62,
      juz: 28,
      from: 73,
      filter: false,
      to: 11,
      name: "سُورَةُ الجُمُعَةِ",
      englishName: "Al-Jumu'a",
      englishNameTranslation: "Friday",
      ayahas: "11",
    },
    {
      number: 63,
      juz: 28,
      from: 84,
      filter: false,
      to: 11,
      name: "سُورَةُ المُنَافِقُونَ",
      englishName: "Al-Munaafiqoon",
      englishNameTranslation: "The Hypocrites",
      ayahas: "11",
    },
    {
      number: 64,
      juz: 28,
      from: 95,
      filter: false,
      to: 18,
      name: "سُورَةُ التَّغَابُنِ",
      englishName: "At-Taghaabun",
      englishNameTranslation: "Mutual Disillusion",
      ayahas: "18",
    },
    {
      number: 65,
      juz: 28,
      from: 113,
      filter: false,
      to: 12,
      name: "سُورَةُ الطَّلَاقِ",
      englishName: "At-Talaaq",
      englishNameTranslation: "Divorce",
      ayahas: "12",
    },
    {
      number: 66,
      juz: 28,
      from: 125,
      filter: false,
      to: 12,
      name: "سُورَةُ التَّحۡرِيمِ",
      englishName: "At-Tahrim",
      englishNameTranslation: "The Prohibition",
      ayahas: "12",
    },
  ],

  [
    {
      number: 67,
      juz: 29,
      from: 0,
      filter: false,
      to: 30,

      name: "سُورَةُ المُلۡكِ",
      englishName: "Al-Mulk",
      englishNameTranslation: "The Sovereignty",
      ayahas: "30",
    },
    {
      number: 68,
      juz: 29,
      from: 30,
      filter: false,
      to: 52,

      name: "سُورَةُ القَلَمِ",
      englishName: "Al-Qalam",
      englishNameTranslation: "The Pen",
      ayahas: "52",
    },
    {
      number: 69,
      juz: 29,
      from: 82,
      filter: false,
      to: 52,

      name: "سُورَةُ الحَاقَّةِ",
      englishName: "Al-Haaqqa",
      englishNameTranslation: "The Reality",
      ayahas: "52",
    },
    {
      number: 70,
      juz: 29,
      from: 134,
      filter: false,
      to: 44,

      name: "سُورَةُ المَعَارِجِ",
      englishName: "Al-Ma'aarij",
      englishNameTranslation: "The Ascending Stairways",
      ayahas: "44",
    },
    {
      number: 71,
      juz: 29,
      from: 178,
      filter: false,
      to: 28,
      name: "سُورَةُ نُوحٍ",
      englishName: "Nooh",
      englishNameTranslation: "Noah",
      ayahas: "28",
    },
    {
      number: 72,
      juz: 29,
      from: 206,
      filter: false,
      to: 28,
      name: "سُورَةُ الجِنِّ",
      englishName: "Al-Jinn",
      englishNameTranslation: "The Jinn",
      ayahas: "28",
    },
    {
      number: 73,
      juz: 29,
      from: 234,
      filter: false,
      to: 20,
      name: "سُورَةُ المُزَّمِّلِ",
      englishName: "Al-Muzzammil",
      englishNameTranslation: "The Enshrouded One",
      ayahas: "20",
    },
    {
      number: 74,
      juz: 29,
      from: 254,
      filter: false,
      to: 56,
      name: "سُورَةُ المُدَّثِّرِ",
      englishName: "Al-Muddaththir",
      englishNameTranslation: "The Cloaked One",
      ayahas: "56",
    },
    {
      number: 75,
      juz: 29,
      from: 310,
      filter: false,
      to: 40,
      name: "سُورَةُ القِيَامَةِ",
      englishName: "Al-Qiyaama",
      englishNameTranslation: "The Resurrection",
      ayahas: "40",
    },
    {
      number: 76,
      juz: 29,
      from: 350,
      filter: false,
      to: 31,
      name: "سُورَةُ الإِنسَانِ",
      englishName: "Al-Insaan",
      englishNameTranslation: "Man",
      ayahas: "31",
    },
    {
      number: 77,
      juz: 29,
      from: 381,
      filter: false,
      to: 50,
      name: "سُورَةُ المُرۡسَلَاتِ",
      englishName: "Al-Mursalaat",
      englishNameTranslation: "The Emissaries",
      ayahas: "50",
    },
  ],

  [
    {
      number: 78,
      juz: 30,
      from: 0,
      filter: false,
      to: 40,
      name: "سُورَةُ النَّبَإِ",
      englishName: "An-Naba",
      englishNameTranslation: "The Announcement",
      ayahas: "40",
    },
    {
      number: 79,
      juz: 30,
      from: 40,
      filter: false,
      to: 46,
      name: "سُورَةُ النَّازِعَاتِ",
      englishName: "An-Naazi'aat",
      englishNameTranslation: "Those who drag forth",
      ayahas: "46",
    },
    {
      number: 80,
      juz: 30,
      from: 86,
      filter: false,
      to: 42,
      name: "سُورَةُ عَبَسَ",
      englishName: "Abasa",
      englishNameTranslation: "He frowned",
      ayahas: "42",
    },
    {
      number: 81,
      juz: 30,
      from: 128,
      filter: false,
      to: 29,
      name: "سُورَةُ التَّكۡوِيرِ",
      englishName: "At-Takwir",
      englishNameTranslation: "The Overthrowing",
      ayahas: "29",
    },
    {
      number: 82,
      juz: 30,
      from: 157,
      filter: false,
      to: 19,
      name: "سُورَةُ الانفِطَارِ",
      englishName: "Al-Infitaar",
      englishNameTranslation: "The Cleaving",
      ayahas: "19",
    },
    {
      number: 83,
      juz: 30,
      from: 176,
      filter: false,
      to: 36,
      name: "سُورَةُ المُطَفِّفِينَ",
      englishName: "Al-Mutaffifin",
      englishNameTranslation: "Defrauding",
      ayahas: "36",
    },
    {
      number: 84,
      juz: 30,
      from: 212,
      filter: false,
      to: 25,
      name: "سُورَةُ الانشِقَاقِ",
      englishName: "Al-Inshiqaaq",
      englishNameTranslation: "The Splitting Open",
      ayahas: "25",
    },
    {
      number: 85,
      juz: 30,
      from: 237,
      filter: false,
      to: 22,
      name: "سُورَةُ البُرُوجِ",
      englishName: "Al-Burooj",
      englishNameTranslation: "The Constellations",
      ayahas: "22",
    },
    {
      number: 86,
      juz: 30,
      from: 259,
      filter: false,
      to: 17,
      name: "سُورَةُ الطَّارِقِ",
      englishName: "At-Taariq",
      englishNameTranslation: "The Morning Star",
      ayahas: "17",
    },
    {
      number: 87,
      juz: 30,
      from: 276,
      filter: false,
      to: 19,
      name: "سُورَةُ الأَعۡلَىٰ",
      englishName: "Al-A'laa",
      englishNameTranslation: "The Most High",
      ayahas: "19",
    },
    {
      number: 88,
      juz: 30,
      from: 295,
      filter: false,
      to: 26,
      name: "سُورَةُ الغَاشِيَةِ",
      englishName: "Al-Ghaashiya",
      englishNameTranslation: "The Overwhelming",
      ayahas: "26",
    },
    {
      number: 89,
      juz: 30,
      from: 321,
      filter: false,
      to: 30,
      name: "سُورَةُ الفَجۡرِ",
      englishName: "Al-Fajr",
      englishNameTranslation: "The Dawn",
      ayahas: "30",
    },
    {
      number: 90,
      juz: 30,
      from: 351,
      filter: false,
      to: 20,
      name: "سُورَةُ البَلَدِ",
      englishName: "Al-Balad",
      englishNameTranslation: "The City",
      ayahas: "20",
    },
    {
      number: 91,
      juz: 30,
      from: 371,
      filter: false,
      to: 15,
      name: "سُورَةُ الشَّمۡسِ",
      englishName: "Ash-Shams",
      englishNameTranslation: "The Sun",
      ayahas: "15",
    },
    {
      number: 92,
      juz: 30,
      from: 386,
      filter: false,
      to: 21,
      name: "سُورَةُ اللَّيۡلِ",
      englishName: "Al-Lail",
      englishNameTranslation: "The Night",
      ayahas: "21",
    },
    {
      number: 93,
      juz: 30,
      from: 407,
      filter: false,
      to: 11,
      name: "سُورَةُ الضُّحَىٰ",
      englishName: "Ad-Dhuhaa",
      englishNameTranslation: "The Morning Hours",
      ayahas: "11",
    },
    {
      number: 94,
      juz: 30,
      from: 418,
      filter: false,
      to: 8,
      name: "سُورَةُ الشَّرۡحِ",
      englishName: "Ash-Sharh",
      englishNameTranslation: "The Consolation",
      ayahas: "8",
    },
    {
      number: 95,
      juz: 30,
      from: 426,
      filter: false,
      to: 8,
      name: "سُورَةُ التِّينِ",
      englishName: "At-Tin",
      englishNameTranslation: "The Fig",
      ayahas: "8",
    },
    {
      number: 96,
      juz: 30,
      from: 434,
      filter: false,
      to: 19,
      name: "سُورَةُ العَلَقِ",
      englishName: "Al-Alaq",
      englishNameTranslation: "The Clot",
      ayahas: "19",
    },
    {
      number: 97,
      juz: 30,
      from: 453,
      filter: false,
      to: 5,
      name: "سُورَةُ القَدۡرِ",
      englishName: "Al-Qadr",
      englishNameTranslation: "The Power, Fate",
      ayahas: "5",
    },
    {
      number: 98,
      juz: 30,
      from: 458,
      filter: false,
      to: 8,
      name: "سُورَةُ البَيِّنَةِ",
      englishName: "Al-Bayyina",
      englishNameTranslation: "The Evidence",
      ayahas: "8",
    },
    {
      number: 99,
      juz: 30,
      from: 466,
      filter: false,
      to: 8,
      name: "سُورَةُ الزَّلۡزَلَةِ",
      englishName: "Az-Zalzala",
      englishNameTranslation: "The Earthquake",
      ayahas: "8",
    },
    {
      number: 100,
      juz: 30,
      from: 474,
      filter: false,
      to: 11,
      name: "سُورَةُ العَادِيَاتِ",
      englishName: "Al-Aadiyaat",
      englishNameTranslation: "The Chargers",
      ayahas: "11",
    },
    {
      number: 101,
      juz: 30,
      from: 485,
      filter: false,
      to: 11,
      name: "سُورَةُ القَارِعَةِ",
      englishName: "Al-Qaari'a",
      englishNameTranslation: "The Calamity",
      ayahas: "11",
    },
    {
      number: 102,
      juz: 30,
      from: 496,
      filter: false,
      to: 8,
      name: "سُورَةُ التَّكَاثُرِ",
      englishName: "At-Takaathur",
      englishNameTranslation: "Competition",
      ayahas: "8",
    },
    {
      number: 103,
      juz: 30,
      from: 504,
      filter: false,
      to: 3,
      name: "سُورَةُ العَصۡرِ",
      englishName: "Al-Asr",
      englishNameTranslation: "The Declining Day, Epoch",
      ayahas: "3",
    },
    {
      number: 104,
      juz: 30,
      from: 507,
      filter: false,
      to: 9,
      name: "سُورَةُ الهُمَزَةِ",
      englishName: "Al-Humaza",
      englishNameTranslation: "The Traducer",
      ayahas: "9",
    },
    {
      number: 105,
      juz: 30,
      from: 516,
      filter: false,
      to: 5,
      name: "سُورَةُ الفِيلِ",
      englishName: "Al-Fil",
      englishNameTranslation: "The Elephant",
      ayahas: "5",
    },
    {
      number: 106,
      juz: 30,
      from: 521,
      filter: false,
      to: 4,
      name: "سُورَةُ قُرَيۡشٍ",
      englishName: "Quraish",
      englishNameTranslation: "Quraysh",
      ayahas: "4",
    },
    {
      number: 107,
      juz: 30,
      from: 525,
      filter: false,
      to: 7,
      name: "سُورَةُ المَاعُونِ",
      englishName: "Al-Maa'un",
      englishNameTranslation: "Almsgiving",
      ayahas: "7",
    },
    {
      number: 108,
      juz: 30,
      from: 532,
      filter: false,
      to: 3,
      name: "سُورَةُ الكَوۡثَرِ",
      englishName: "Al-Kawthar",
      englishNameTranslation: "Abundance",
      ayahas: "3",
    },
    {
      number: 109,
      juz: 30,
      from: 535,
      filter: false,
      to: 6,
      name: "سُورَةُ الكَافِرُونَ",
      englishName: "Al-Kaafiroon",
      englishNameTranslation: "The Disbelievers",
      ayahas: "6",
    },
    {
      number: 110,
      juz: 30,
      from: 541,
      filter: false,
      to: 3,
      name: "سُورَةُ النَّصۡرِ",
      englishName: "An-Nasr",
      englishNameTranslation: "Divine Support",
      ayahas: "3",
    },
    {
      number: 111,
      juz: 30,
      from: 544,
      filter: false,
      to: 5,
      name: "سُورَةُ المَسَدِ",
      englishName: "Al-Masad",
      englishNameTranslation: "The Palm Fibre",
      ayahas: "5",
    },
    {
      number: 112,
      juz: 30,
      from: 549,
      filter: false,
      to: 4,
      name: "سُورَةُ الإِخۡلَاصِ",
      englishName: "Al-Ikhlaas",
      englishNameTranslation: "Sincerity",
      ayahas: "4",
    },
    {
      number: 113,
      juz: 30,
      from: 553,
      filter: false,
      to: 5,
      name: "سُورَةُ الفَلَقِ",
      englishName: "Al-Falaq",
      englishNameTranslation: "The Dawn",
      ayahas: "5",
    },
    {
      number: 114,
      juz: 30,
      from: 558,
      filter: false,
      to: 6,
      name: "سُورَةُ النَّاسِ",
      englishName: "An-Naas",
      englishNameTranslation: "Mankind",
      ayahas: "6",
    },
  ],
];
