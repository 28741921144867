import React from "react";

const Logo = ({ width, height }) => {
  return (
    <div style={{ width: width, height: height }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1711.615 1650.879"
        enable-background="new 0 0 1711.615 1650.879"
      >
        <g>
          <path
            d="M1053.585,26.916c179.482,138.821,284.704,352.821,285.047,579.724c0,405.54-328.755,734.295-734.295,734.295c0,0,0,0,0,0
		c-225.71-0.083-438.841-103.965-577.961-281.702c109.397,337.226,423.374,565.758,777.9,566.206
		c452.263,0,818.894-366.631,818.894-818.894c0,0,0,0,0,0c-0.15-356.075-230.383-671.243-569.55-779.664L1053.585,26.916z"
          />
          <path
            d="M708.712,340.969c12.374,9.573,19.627,24.327,19.65,39.971c0,27.96-22.664,50.627-50.624,50.63
		c-15.563,0-30.26-7.164-39.855-19.418c7.469,22.991,28.745,38.677,52.916,39.013l-4.119,33.902l2.237,14.703
		c-3.497,1.576-5.748,5.052-5.754,8.887c0.003,3.773,2.18,7.206,5.59,8.819c-4.572,1.734-7.597,6.112-7.601,11.001
		c0.008,4.079,2.125,7.863,5.597,10.003c-5.158,2.403-8.458,7.576-8.464,13.266c0.005,4.183,1.798,8.164,4.926,10.941
		c-44.082,70.48-145.85,94.378-157.506,142.463c-12.621,52.063,44.178,85.199,44.178,85.199h1.273
		c-8.433,0-15.224,6.783-15.224,15.216c0,3.697,1.355,7.036,3.53,9.668c-35.915,11.745-61.071,30.055-93.39,43.994v12.199
		c-13.64,5.627-27.321,11.239-42.297,16.235v10.961c-9.108,3.02-18.339,5.997-27.963,8.819v-66.312l21.908-22.605v-10.242h-21.908
		V684.652l21.908-22.605v-10.242h-21.908v-107.22l21.908-22.599v-10.249h-24.05l-31.849-132.113l-31.849,132.113H305.93v10.249
		l21.901,22.599v107.22h-21.907v10.242l21.901,22.605v123.631h-21.908v10.242l21.901,22.605v196.13H214.827v-39.649l21.901-22.606
		v-10.242h-21.901V841.132l21.901-22.605v-10.242h-21.901v-107.22l21.901-22.606v-10.242h-24.043l-31.849-132.12l-31.849,132.12
		h-24.043v10.242l21.9,22.606v107.22h-21.907v10.242l21.901,22.605v123.631h-21.908v10.242l21.901,22.606v257.226h67.975
		l164.005,105.004H482.75v-333.026c0,0-3.836-59.941,53.675-91.57c57.512,31.631,53.675,91.57,53.675,91.57v333.026h49.132v-333.026
		c0,0-3.836-59.941,53.675-91.57c57.511,31.631,53.675,91.57,53.675,91.57v333.026h49.125v-333.026c0,0-3.83-59.941,53.682-91.57
		c57.511,31.631,53.675,91.57,53.675,91.57v333.026h154.945L1171,1254.838h67.975V997.612l21.908-22.606v-10.242h-21.908V841.132
		l21.908-22.605v-10.242h-21.908v-107.22l21.908-22.606v-10.242h-24.043l-31.849-132.12l-31.849,132.12h-24.049v10.242L1171,701.065
		v107.22h-21.908v10.242L1171,841.132v123.631h-21.908v10.242L1171,997.612v39.649h-112.991v-196.13l21.908-22.605v-10.242h-21.908
		V684.652l21.908-22.605v-10.242h-21.908v-107.22l21.908-22.599v-10.249h-24.043l-31.849-132.113l-31.849,132.113h-24.05v10.249
		l21.908,22.599v107.22h-21.908v10.242l21.908,22.605v123.631h-21.908v10.242l21.908,22.605v68.7
		c-12.73-3.512-24.667-7.327-36.406-11.221v-10.947c-14.976-4.996-28.656-10.608-42.297-16.235v-12.199
		c-30.413-13.116-54.458-30.118-87.111-41.866c3.446-2.786,5.692-6.999,5.692-11.796c0-8.433-6.79-15.216-15.224-15.216h1.272
		c0,0,56.8-33.133,44.178-85.199c-11.656-48.083-113.429-71.983-157.506-142.463c3.129-2.776,4.921-6.758,4.926-10.941
		c-0.008-5.688-3.307-10.857-8.463-13.259c3.471-2.143,5.586-5.93,5.59-10.01c-0.006-4.891-3.034-9.27-7.608-11.001
		c3.413-1.611,5.592-5.045,5.597-8.819c-0.001-3.835-2.247-7.313-5.74-8.895l2.23-14.696l-4.139-34.06
		c29.726-1.863,52.893-26.497,52.929-56.282c-0.009-24.55-15.882-46.28-39.266-53.757L708.712,340.969z M361.821,565.336
		c23.789,13.084,22.202,37.877,22.202,37.877v31.87H339.62v-31.87C339.62,603.213,338.033,578.42,361.821,565.336L361.821,565.336z
		 M1024.049,565.336c23.789,13.084,22.202,37.877,22.202,37.877v31.87h-44.404v-31.87
		C1001.847,603.213,1000.26,578.42,1024.049,565.336L1024.049,565.336z M361.821,718.277c23.789,13.084,22.202,37.877,22.202,37.877
		v31.87H339.62v-31.87C339.62,756.154,338.033,731.361,361.821,718.277L361.821,718.277z M1024.049,718.277
		c23.789,13.084,22.202,37.877,22.202,37.877v31.87h-44.404v-31.87C1001.847,756.154,1000.26,731.361,1024.049,718.277
		L1024.049,718.277z M180.883,721.814c23.789,13.084,22.202,37.877,22.202,37.877v31.863h-44.404v-31.863
		C158.681,759.691,157.094,734.898,180.883,721.814z M1205.03,721.814c23.789,13.084,22.203,37.877,22.203,37.877v31.863h-44.404
		v-31.863C1182.828,759.691,1181.241,734.898,1205.03,721.814L1205.03,721.814z M180.883,874.755
		c23.789,13.084,22.202,37.877,22.202,37.877v31.863h-44.404v-31.863C158.681,912.632,157.094,887.839,180.883,874.755z
		 M1205.03,874.755c23.789,13.084,22.203,37.877,22.203,37.877v31.863h-44.404v-31.863
		C1182.828,912.632,1181.241,887.839,1205.03,874.755L1205.03,874.755z"
          />
          <g transform="matrix(3.0353 0 0 3.0353 1261.5 3029.4)">
            <path
              d="M-198.726-989.675c-4.395,3.4-6.972,8.64-6.98,14.197c0,9.931,8.051,17.982,17.982,17.982
			c5.527-0.002,10.747-2.546,14.154-6.898c-2.679,8.258-10.368,13.854-19.049,13.865c-11.075,0-20.054-8.978-20.054-20.054
			C-212.669-979.302-207.031-987.02-198.726-989.675L-198.726-989.675z"
            />
            <path
              d="M-265.001-969.707l5.732,2.886l0.974,6.344l4.517-4.56l6.334,1.034l-2.941-5.705l2.941-5.705l-6.334,1.034l-4.517-4.56
			l-0.974,6.344L-265.001-969.707z"
            />
            <path
              d="M-227.518-978.553l-2.679-0.138l-1.442-2.263l-0.959,2.506l-2.597,0.672l2.087,1.686l-0.163,2.678l2.249-1.463
			l2.496,0.983l-0.697-2.591L-227.518-978.553z"
            />
            <path
              d="M-298.349-965.933l3.581,2.954l-0.329,4.63l3.916-2.493l4.302,1.743l-1.161-4.494l2.987-3.553l-4.633-0.285l-2.456-3.939
			l-1.702,4.319L-298.349-965.933z"
            />
            <path
              d="M-333.513-943.483l3.581-2.954l-0.329-4.63l3.916,2.493l4.302-1.744l-1.161,4.495l2.988,3.553l-4.633,0.285l-2.456,3.939
			l-1.703-4.318L-333.513-943.483z"
            />
            <path
              d="M49.915-869.987l-3.581-2.954l0.329-4.63l-3.916,2.493l-4.302-1.743l1.161,4.494l-2.987,3.553l4.633,0.285l2.456,3.939
			l1.702-4.319L49.915-869.987z"
            />
            <path
              d="M-299.419-952.333l-2.598-2.142l0.238-3.359l-2.84,1.808l-3.121-1.265l0.842,3.26l-2.167,2.577l3.361,0.206l1.781,2.857
			l1.235-3.133L-299.419-952.333z"
            />
            <path
              d="M-221.309-954.916l-2.679-0.138l-1.442-2.263l-0.959,2.506l-2.597,0.672l2.087,1.686l-0.163,2.678l2.249-1.463
			l2.496,0.983l-0.697-2.591L-221.309-954.916z"
            />
            <path
              d="M-270.296-957.739l2.679-0.138l1.442-2.263l0.959,2.506l2.597,0.672l-2.087,1.686l0.163,2.678l-2.249-1.464l-2.496,0.983
			l0.697-2.591L-270.296-957.739z"
            />
            <path
              d="M-322.184-927.822l2.679-0.138l1.442-2.263l0.959,2.506l2.597,0.672l-2.087,1.686l0.163,2.678l-2.249-1.463l-2.496,0.983
			l0.697-2.591L-322.184-927.822z"
            />
            <path
              d="M131.926-880.355l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L131.926-880.355z"
            />
            <path
              d="M-191.92-940.117l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L-191.92-940.117z"
            />
            <path
              d="M-164.078-949.078l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L-164.078-949.078z"
            />
            <path
              d="M-143.616-950.41l3.581,2.954l-0.329,4.63l3.916-2.493l4.302,1.743l-1.161-4.494l2.987-3.553l-4.633-0.285l-2.456-3.939
			l-1.702,4.319L-143.616-950.41z"
            />
            <path
              d="M97.805-876.138l3.581-2.954l-0.329-4.63l3.916,2.493l4.302-1.744l-1.161,4.495l2.988,3.553l-4.633,0.285l-2.456,3.939
			l-1.703-4.318L97.805-876.138z"
            />
            <path
              d="M-12.096-877.665l3.581,2.954l-0.329,4.63l3.916-2.493l4.302,1.743l-1.161-4.494l2.987-3.553l-4.633-0.285l-2.456-3.939
			l-1.702,4.319L-12.096-877.665z"
            />
            <path
              d="M-109.113-930.443l3.581,2.954l-0.329,4.63l3.916-2.493l4.302,1.743l-1.161-4.495l2.987-3.553l-4.633-0.285l-2.456-3.939
			l-1.702,4.319L-109.113-930.443z"
            />
            <path
              d="M-48.574-892.906l3.581,2.954l-0.329,4.63l3.916-2.493l4.302,1.743l-1.161-4.494l2.987-3.553l-4.633-0.285l-2.456-3.939
			l-1.702,4.319L-48.574-892.906z"
            />
            <path
              d="M-103.889-901.31l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L-103.889-901.31z"
            />
            <path
              d="M-128.953-923.183l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L-128.953-923.183z"
            />
            <path
              d="M-28.332-869.489l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.464l-2.496-0.983
			l0.697,2.591L-28.332-869.489z"
            />
            <path
              d="M-76.657-888.751l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.463l-2.496-0.983
			l0.697,2.591L-76.657-888.751z"
            />
            <path
              d="M-85.157-906.249l2.679,0.138l1.442,2.263l0.959-2.506l2.597-0.672l-2.087-1.686l0.163-2.678l-2.249,1.464l-2.496-0.983
			l0.697,2.591L-85.157-906.249z"
            />
            <path
              d="M-355.643-936.007l2.679-0.138l1.442-2.263l0.959,2.506l2.597,0.672l-2.087,1.686l0.163,2.678l-2.249-1.463l-2.496,0.983
			l0.697-2.591L-355.643-936.007z"
            />
            <path
              d="M-349.257-918.156l2.679-0.138l1.442-2.263l0.959,2.506l2.597,0.672l-2.087,1.686l0.163,2.678l-2.249-1.463l-2.496,0.983
			l0.697-2.591L-349.257-918.156z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
