import { Box } from "@mui/material";
import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privact_container">
      <h2 className="privacy_policy_heading">Privacy Policy</h2>
      <Box sx={{ width: { xs: "90%", md: "80%", lg: "70%" }, margin: "auto" }}>
        <div>
          <p className="privacy_policy_content">
            <b>WORLD AZAN TIME</b> is a new <b>app</b> and website built PURELY
            AS A SADAQHA JARRIYA PROJECT to provide you with an accurate azan
            time worldwide for every Muslim. The aim is to share it and earn the
            beautiful deed of Sadaqah Jarriya for yourself and your loved ones
            for the rest of your life.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            In the short term, we will focus on Prayer (Salah) by building an
            elegant ad-free <b>app</b> that prioritises your privacy. WE WILL
            NOT HOLD AND SHARE YOUR PERSONAL INFORMATION ON OUR WEBSITE AND APP.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            Jazaak Allah khair
          </p>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <p className="privacy_policy_content privacy_policy_content2">
            This privacy policy is to let you know how World Azan Time handles
            data.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            This privacy policy is to let you know how World Azan Time handles
            data.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            This notice provides you with information regarding your rights and
            explains how, why and when we collect and process your data.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            If you have any questions or want to exercise any of your rights set
            out in this privacy notice, please contact us at
            info@worldazantime.com,
          </p>

          <h2 style={{ textAlign: "start", marginTop: "12px" }}>About Us</h2>
          <p className="privacy_policy_content privacy_policy_content2">
            We are trading as World Azan Time work as <b>SADAQAH JARRIYAH</b>{" "}
            project.
          </p>
          <p className="privacy_policy_content privacy_policy_content2">
            We can be contacted by email a info@worldazantime.com.
          </p>
          <h2 style={{ textAlign: "start", marginTop: "12px" }}>
            What this notice covers
          </h2>
        </div>
        <p className="privacy_policy_content privacy_policy_content2">
          This privacy notice covers the Worldazantime App v1.x only.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          Future versions of the World Azan Time App may involve changes to data
          handling policy. This will require you to review and agree to a new
          privacy policy covering the new changes and/or features.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          We don't collect location or personal* data
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          We believe all users of the app (both Muslims and non-Muslims) are
          entitled to their privacy and have built Pillars around this central
          principle.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          Therefore, whilst the app requires your location data to calculate
          bespoke prayer times for your specific geolocation, this is all done
          locally (i.e. on the phone) and no data whatsoever is sent to or store
          World Azan Time by ourselves.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          No other data is collected (including analytics or personal
          information*).
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          *this excludes email addresses if you have signed up to our mailing
          list (to facilitate email updates).
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Email address data
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          We collect the email addresses of individuals who have signed up to
          our mailing list or beta programme, as well as details of your
          engagement with our emails. This is limited to information about
          whether you open our emails or not and click on links inside them.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Global transfer and processing
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          We do not collect any data apart from your email address. This may be
          stored and processed in any country where we operate, or where other
          companies provide services to us (in this case MailChimp). By signing
          up to our mailing list, you're agreeing to let us store it in, and
          transfer your email address to these countries.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          Flodesk & Revue
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Google Analytics
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          We use Google Analytics as a service provider in order to receive
          anonymous website analytical data that helps us improve our website
          experience. The way Google Analytics store and process your data is
          subject to their Privacy Policy.{" "}
          <a href="https://policies.google.com/privacy?hl=en-US">View</a>
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Email address retention
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          We'll keep your email address and name for the purpose of sending you
          updates until either we decide to stop sending updates, or you ask us
          to delete your data by getting in touch at info@worldazantime.com.
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          Your marketing preferences
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          When you sign up to our mailing list, you are opting in to receive
          updates on our product by email. You can opt out of these email
          communications at any time by clicking the "unsubscribe" link at the
          bottom of any message we send to you, or by contacting us at
          info@worldazantime.com.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Your rights (in general)
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          Under data protection regulation, you have...
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to be informed about the collection and use of your
          personal data
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to access the personal data we hold about you via a
          Subject Access Request
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to correct inaccurate personal data
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to have your personal data deleted
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to request restriction of or suppression of your personal
          data
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to make use of your personal data for your own purposes
          across different services
        </p>
        <p className="privacy_policy_content privacy_policy_content2">
          • the right to object to the processing of your personal data in some
          circumstances.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Making a rights request
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          If you would like to exercise any of the rights outlined above, please
          contact us by email at info@worldazantime.com.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Making a complaint
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          You have the right to complain to us and to the data protection
          regulator, the Information Commissioner’s Office. Their address is
          Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, United
          Kingdom. They can be contacted by phone on +44303 123 1113 (local
          rate) or +441625 545745 (national rate). You can find details on how
          to report a concern at ico.org.uk/make-a-complaint/
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Links to other sites
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          Our website may contain hyperlinks to websites that are not operated
          by us. Please review the privacy policy posted on any site you visit
          before using the site or providing any personal information about
          yourself.
        </p>
        <h2 style={{ textAlign: "start", marginTop: "12px" }}>
          Changes in the Privacy Policy
        </h2>
        <p className="privacy_policy_content privacy_policy_content2">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices, new features or for
          other operational or legal reasons. Updated versions of this policy
          will be posted on our website.
        </p>

        <h2 style={{ textAlign: "start", marginTop: "12px" }}>Contact us</h2>
        <p className="privacy_policy_content privacy_policy_content2">
          For more information about our Privacy Notice, or if you would like to
          make a complaint, please contact us info@worldazantime.com
        </p>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
