import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import playstore from "../images/playstore.png";
import appstore from "../images/appstore.png";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: "black", p: 1 }}>
      <Stack direction="row" justifyContent={"center"} sx={{ my: 1 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <p
            className="footer_privacy"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <div className="vertical_line"></div>
          <p className="footer_privacy" onClick={() => navigate("/aboutus")}>
            About
          </p>
          {/* <div className="vertical_line"></div>
          <p className="footer_privacy" onClick={() => navigate("/resources")}>
            Resources
          </p> */}
        </Stack>
      </Stack>
      <Typography
        variant="h5"
        sx={{
          color: "rgba(255,255,255,0.7)",
          letterSpacing: "1px",
          textTransform: "capitalize",
          display: { xs: "block", md: "none" },
        }}
      >
        A sadaqah jarriya project <br /> Share and Earn Sadaqah Jarriya
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "rgba(255,255,255,0.7)",
          letterSpacing: "1px",
          textTransform: "capitalize",
          display: { xs: "none", md: "block" },
          mb: 1,
        }}
      >
        A sadaqah jarriya project Share and Earn Sadaqah Jarriya
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        gap={{ xs: 1, sm: 2 }}
        sx={{ my: 1, width: "70%", m: "auto" }}
      >
        <Box
          sx={{
            height: { xs: 50, sm: 70 },
          }}
        >
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.worldazantime",
                "_blank"
              )
            }
            src={playstore}
            height={"100%"}
            objectFit="contain"
            alt="playstore"
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          sx={{
            height: { xs: 50, sm: 70 },
          }}
        >
          <img
            src={appstore}
            height={"100%"}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/world-azan-time/id1638607557",
                "_blank"
              )
            }
            objectFit="contain"
            alt="playstore"
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      </Stack>

      <Typography
        variant="body1"
        sx={{
          color: "rgba(255,255,255,0.7)",
          textTransform: "capitalize",
          marginTop: "5px",
        }}
      >
        Developed By
        <a
          href="https://zmsofttech.com/"
          className="footer_dev__info"
          target="_blank"
          rel="noreferrer"
        >
          ZM Soft Tech
        </a>
      </Typography>
      <p style={{ color: "rgba(255,255,255,0.5)", marginTop: "5px" }}>
        Copyright &#169; {new Date().getFullYear()} Worldazantime.com AllRights
        Reserved
      </p>
    </Box>
  );
};

export default Footer;
